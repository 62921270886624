#professional-experience {
}

/* Mobile */
@media only screen and (min-width: 320px) {
}

/* Tablet */
@media only screen and (min-width: 768px) {
}

/* Desktop */
@media only screen and (min-width: 1200px) {
  #professional-experience {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .professional-experience-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .professional-experience-text {
    width: 50%;
    display: flex;
  }

  .professional-experience-text-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .professional-experience-content-carousel {
    width: 50%;
  }
}
