.LayoutThemeSwitcher {
  z-index: 2;
}

.LayoutThemeSwitcher-button {
  outline: none;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
  top: 14px;
  cursor: pointer;
}
