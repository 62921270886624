.NavDesktop {
  padding-top: 14px;
}

.NavDesktop.mobile {
  display: none;
}

.NavDesktop-link {
  text-decoration: none;
  padding: 0px 12px;
}
