.SkillButton {
  display: inline-block;
  padding: 4px 6px;
  margin: 4px;
  font-size: 14px;
  border-radius: 6px;
}

.SkillButton.clickable {
  cursor: pointer;
}

.SkillButton.clickable:hover {
  opacity: 0.6;
}

.SkillButton-name {
  margin: 0;
}
