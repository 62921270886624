.ProjectSlide {
  text-decoration: none;
}

.ProjectSlide-tile {
  padding-bottom: 16px;
  margin: 0 auto;

  /* Mobile */
  max-width: 600px;
  width: calc(100% - 28px);
  border-radius: 12px;
  padding: 12px;
  height: 400px;
}

.ProjectSlide-name-date {
}

.ProjectSlide-name {
  margin-bottom: 8px;
}

.ProjectSlide-date {
  margin-top: 8px;
  margin-bottom: 8px;
}

.ProjectSlide-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.ProjectSlide-skills {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
}
