.Section {
  width: 100%;
  height: fit-content;
  padding-top: 54px;
}

@media (min-width: 1200px) {
  .Section {
    padding-bottom: 60px;
  }
}
