#personal-projects {
}

/* Mobile */
@media only screen and (min-width: 320px) {
}

/* Tablet */
@media only screen and (min-width: 768px) {
}

/* Desktop */
@media only screen and (min-width: 1200px) {
  #personal-projects {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .personal-projects-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .personal-projects-text {
    width: 50%;
    display: flex;
  }

  .personal-projects-text-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .personal-projects-content-carousel {
    width: 50%;
  }
}
