.MailLink {
  width: 32px;
  height: 32px;
}

.MailLink-modal {
  position: absolute;
  width: 0;
  height: 0;
}

.MailLink-modal-tail {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: relative;
  left: 13px;
}

.MailLink-modal-content {
  position: relative;
  left: -140px;
  width: 280px;
  padding: 8px;
  margin: 0 auto;
  border-radius: 4px;
}

.MailLink-modal-content p {
  margin: 0;
  display: inline;
  text-align: center;
}

.MailLink-modal-content a {
  display: inline;
  margin: 0;
}

.MailLink-button {
  border: 0;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.MailLink-button:hover {
  opacity: 0.6;
}
