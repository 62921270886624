.WorkSlide {
  text-decoration: none;
}

.WorkSlide-tile {
  padding-bottom: 16px;
  margin: 0 auto;

  /* Mobile */
  max-width: 600px;
  width: calc(100% - 28px);
  border-radius: 12px;
  padding: 12px;
  height: 400px;
}

.WorkSlide-company {
  margin-bottom: 8px;
}

.WorkSlide-job-title {
  margin-top: 8px;
  margin-bottom: 8px;
}

.WorkSlide-date {
  margin-top: 8px;
  margin-bottom: 8px;
}

.WorkSlide-skills {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
}
