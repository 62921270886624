.ViewAllButton {
  height: 32px;
  padding-top: 9px;
  font-size: 14px;
  opacity: 0.6;
  cursor: pointer;
  display: inline-flex;
  text-decoration: none;
}

.ViewAllButton:hover {
  opacity: 1;
}
