.ReadMore {
  white-space: pre-wrap;
  position: relative;
  width: 100%;
  margin: 0px auto;
}

.ReadMore[data-expanded="false"] {
  max-height: 150px;
  overflow: hidden;
}

.ReadMore-overlay[data-expanded="true"] {
  display: none;
}

.ReadMore-overlay[data-expanded="false"] {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.ReadMore-toggle {
}

.ReadMore[data-expanded="true"] {
}
