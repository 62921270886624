.ImageCarousel {
}

.ImageCarousel-slide {
}

.ImageCarousel-image {
  max-width: 100%;
  height: auto;
}

.ImageCarousel-legend {
}

/** react-responsive-carousel style overrides */
/* .image-carousel .carousel .thumbs-wrapper {
  margin: 0;
  padding-top: 12px;
  padding-bottom: 12px;
}*/

.ImageCarousel {
  margin-top: 48px;
}

@media (min-width: 768px) {
  .ImageCarousel {
    margin-top: none;
  }
}
