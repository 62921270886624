.SectionHeader {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
}

.SectionHeader-title {
  font-size: 4rem;
  text-align: left;
  margin-top: 0;
  user-select: none;
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .SectionHeader {
    width: unset;
    max-width: unset;
  }

  .SectionHeader-title {
    margin-bottom: 54px;
    text-indent: 12px;
  }
}
