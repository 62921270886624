.SearchButton {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.SearchButton.desktop {
  margin-right: 8px;
}

.SearchButton-icon {
  margin-left: 7px;
}
