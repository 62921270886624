.HomeButton {
}

.HomeButton-link {
  transition: opacity 200ms;
  opacity: 0;
}

.HomeButton-link.show {
  opacity: 1;
}

.HomeButton-icon {
  position: relative;
  top: 10px;
}
