.Footer {
  padding: 18px;
}

.Footer-content {
}

.Footer-logo {
}

.Footer-divider {
}

.Footer-links {
  margin-top: 18px;
}

.Footer-copyright {
  margin: 12px auto;
}
