.NavMobile {
  width: 100%;
  height: 60px;
}

.NavMobile-icon {
  width: 100%;
  height: 60px;
}

.NavMobile-icon-button {
  padding: 0;
  margin: 0;
  padding: 0px !important;
  position: relative;
  top: 14px; /* (header height / 2) - (icon height / 2) - 4 */
  z-index: 2;
}

.NavMobile-menu {
  display: hidden;
  display: grid;
  padding-top: 60px;
}

.NavMobile-menu.active {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  min-width: 200px;
  width: 20%;
  max-width: 400px;
}

.NavMobile-menu-list {
}

.NavMobile-menu-item {
  padding-bottom: 12px;
}

.NavMobile-menu-item > a {
  text-decoration: none;
  opacity: 0.8;
}

.NavMobile-menu-item > a:hover {
  opacity: 1;
}
