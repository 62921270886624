.BackButton {
  display: inline-block;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 0;
  cursor: pointer;
}

.BackButton-inner {
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
}
