#Search {
}

.Search-actions {
}

.Search-results {
}

.Search-results-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.Search-results-content > a {
  margin: 8px;
  max-width: 380px;
}

.Search-actions-skills {
  max-width: 800px;
  margin: 12px auto;
}
