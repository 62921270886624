.App {
  text-align: center;
}

/*
  CSS overrides
*/

.carousel .thumbs-wrapper {
  margin: 0 !important;
}
