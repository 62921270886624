#BackgroundParticles {
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.BackgroundParticles {
  z-index: -1;
  pointer-events: none;
  position: relative;
}
