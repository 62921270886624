.SearchBar {
  position: relative;
  display: inline-block;
}

.SearchBar input {
  outline: none;
  border: 0;
  font-size: 22px;
  border-radius: 16px;
  padding: 4px;
  text-align: center;
}

.SearchBar-clear {
  position: absolute;
  outline: none;
  border: 0;
  border-radius: 50%;
  right: 8px;
  top: 8px;
  z-index: 2;
  cursor: pointer;
}
