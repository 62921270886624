#about {
  /*   margin-top: 100vh; */
}

.about-content {
}

.about-text {
}

.about-images {
  max-width: 600px;
  margin: 0 auto;
  width: calc(100% - 28px);
}

/* Mobile */
@media only screen and (min-width: 320px) {
}

/* Tablet */
@media only screen and (min-width: 768px) {
}

/* Desktop */
@media only screen and (min-width: 1200px) {
  #about {
  }

  .about-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .about-text {
    width: 50%;
    display: flex;
  }

  .about-images {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .about-text-content {
    display: flex;
    flex-direction: column;
  }

  .about-text-divider {
    height: auto;
    flex-grow: 1;
  }
}
