.Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 60px;
  z-index: 10;
}

.Header-divider {
  flex-grow: 1;
}

.Header.mobile {
  display: grid;
  grid-template-columns: 60px auto 40px 40px 40px 40px;
}

.Header.desktop {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.Header.deskop .Header-divider {
  flex-grow: 1;
}
