#landing {
  /*   padding-top: 20vh;
  position: fixed;
  top: 0;
  z-index: -1;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden; */
}

.landing-card {
  border-radius: 8px;
  width: fit-content;
  min-width: 300px;
  max-width: 500px;
  padding: 20px 16px;
  margin: 60px auto;
  /*   background-image: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.08)
  ); */
}

.landing-selfie {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-size: cover;
  user-select: none;
  pointer-events: none;
}

.landing-header {
  pointer-events: none;
  user-select: none;
}

.landing-tagline {
  pointer-events: none;
  user-select: none;
}

.landing-links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 150px;
  margin: 20px auto;
}

.landing-call-to-action {
  display: inline-block;
  font-size: 1.4rem;
  transition: font-size 300ms;
}

.landing-call-to-action:hover {
  font-size: 1.6rem;
}

.landing-call-to-action > a {
  text-decoration: none;
  padding: 12px;
  display: inline-block;
}
