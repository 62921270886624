.ColorThemeSwitcher {
  z-index: 2;
}

.ColorThemeSwitcher-button {
  outline: none;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 0;
  margin: 0;
  position: relative;
  top: 14px;
}

.ColorThemeSwitcher.desktop {
  margin-right: 8px;
}
